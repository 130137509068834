

  <!-- <app-header-menu></app-header-menu> -->
  
  <!-- <div class="controls" *ngIf="isEmbedded; else embedReportFlow">
    <button (click)="makeFullScreen()">fullscreen</button>
    <button (click)="deleteVisual()">Delete Visual</button>
    <button (click)="hideFilterPane()">Hide Filter Pane</button>
    <button (click)="setDataSelectedEvent()">Set Event</button>
    <div class="display-message" #status>{{ displayMessage }}</div>
  </div> -->
  <ng-template #embedReportFlow>
    <!-- <div class="display-message position" #status>
      <label>{{ displayMessage }}</label>
    </div> -->
    <!-- <div class="embed-report">
      <button (click)="embedReport('1d88d2c6-a73f-4d02-ad75-4b66241e8264')" id="embed-action" #embedReportBtn>Embed Report</button>
      <button (click)="embedReport('40d736fe-efc2-4a1a-8a7f-b431b469fd54')" id="embed-action" #embedReportBtn>Embed Report</button>
    </div> -->
    <!-- <div class="separator"></div> -->
  </ng-template>

  <div style="display: flex; flex-direction: row; justify-content: space-between; height: 25px;">
    <app-breadcrumb-viewer *ngIf="!windowsService.isMobileLayout"></app-breadcrumb-viewer>

    <div class="favArea" style="display: flex; justify-content: left; "> 
      <a mat-button style="width: 150px; padding-bottom: 8px;"  (click)="makeFullScreen()">View Full Screen</a>
      <div *ngIf="!isFavorited()"> <i  (click)="favoriteReport()" style="cursor: pointer; font-size: 30px; vertical-align: middle;" class="material-icons">star_border</i></div>
      <div *ngIf="isFavorited()" > <i style="color: gold; cursor: pointer; font-size: 30px; vertical-align: middle;" (click)="deletefavoriteReport()" class="material-icons">star_rate</i>  </div>

      <div style="line-height: 1.8; margin-right: 135px;"  *ngIf="!isFavorited()">Favorite</div>
      <div style="line-height: 1.8; margin-right: 120px;"  *ngIf="isFavorited()">Unfavorite</div>
    </div>
  
  </div>

  

  <div class="message" *ngIf="reportTypeNotVisible">  <h3> Report Type is not accepted yet.  </h3> </div>

  <div class="message"  *ngIf="displayMessage" style="font-size: 25px;">  <h3> {{displayMessage}}</h3> </div>

 
  
  <powerbi-report [embedConfig]="reportConfig" [cssClassName]="reportClass" [phasedEmbedding]="phasedEmbeddingFlag" [eventHandlers]="eventHandlersMap" #report>
  </powerbi-report>

<div class="newsInsightsWrapper" *ngIf="!windowsService.isMobileLayout">

    <div class="newsInsightsContent">
        <div class="newsInsightsHeader">
            Featured Reports
        </div>
    
        <div>
            <p class="headerMessage">Stay connected with the company that’s connecting the dots with what’s happening in the industry and
                around the world.</p>
        </div>
    
        <!-- <div class="newsInsightsRealTimeContent">
            <div class="contentBox" *ngFor="let ni of newsInsightsList; index as i">
                <div>
                    <img (click)="routeToUrl(ni.url)"  class="sectionHeaderImage" [src]="getImageDir(i)" alt="">
                </div>
                <div class="newsInsightsSubHeader">
                    {{ni.title}}
                </div>
                <div class="newsInsightsDescription">
                    {{ni.description}}
                </div>
                <div class="newsInsightsUrlArea">
                    <a class="learnMoreUrls ctaLink"  target="_blank" 
                        [href]="ni.url"><span><b>> </b><b style="color: #00263E;">{{ni.callToAction}}</b></span></a>

                     
                </div>

            </div> 
        </div> -->


        <div class="cardListContainer">
            <mat-card class="example-card" appearance="outlined" *ngFor="let ni of featuredReportList; index as i">
                <img (click)="routeToUrl(ni.url)"  class="sectionHeaderImage" [src]="getImageDir(i)" alt="">
                <!-- <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu"> -->
                <mat-card-content>
                    <div class="newsInsightsSubHeader">
                        <span> {{ni.title}}</span>
                       

                      
                    </div>
                  <p class="description">
                    {{ni.description}}
                  </p>
                </mat-card-content>
                <mat-card-actions>
                    <a class="learnMoreUrls ctaLink"  target="_blank" 
                    [href]="ni.url"><span><b>> </b><b style="color: #00263E;">{{ni.callToAction}}</b></span></a>
                </mat-card-actions>
              </mat-card>
        </div>


        


    </div>

</div>

<div class="newsInsightsWrapper" *ngIf="windowsService.isMobileLayout">

    <div class="newsInsightsContent">
        <div class="newsInsightsHeader">
            Featured featuredReportsFormArray
        </div>
    
        <div>
            <p class="messageText">Stay connected with the company that’s connecting the dots with what’s happening in the industry and
                around the world.</p>
        </div>
    
        <div class="newsInsightsRealTimeContent-Mobile">
            <div class="contentCard" *ngFor="let ni of featuredReportList; index as i">
                <div>
                    <img class="sectionHeaderImage-Mobile" [src]="getImageDir(i)" alt="">
                </div>
                <div class="newsInsightsSubHeader-Mobile">
                    {{ni.title}}
                </div>
                <div class="newsInsightsUrlArea-Mobile">
                    <a  class="learnMoreUrls"  target="_blank" [href]="ni.url"><img
                        src="../../../assets/images/DriveUXInsightsToWinrighticon.svg" alt=""><span class="standardPad">{{ni.callToAction}}</span></a>
                </div>

            </div> 
            
            
           
            
            
            
        </div>
    </div>

</div>



import { Component, Inject, inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { BiGroupDto, BiReportDto } from 'src/app/models/bi';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-edit-treenode-modal',
  templateUrl: './edit-treenode-modal.component.html',
  styleUrls: ['./edit-treenode-modal.component.scss']
})
export class EditTreenodeModalComponent {
  reportIdDropdownValues: BiReportDto[] = [{name: 'Global Report', id: 'd2887447-ec87-4cd6-af17-caebfee0b37e'} as BiReportDto];
  private _groupIdDropdownValues: BiGroupDto[] = [{name: 'AJG WorkSpace', id: '42063e3c-aafa-4c32-82c2-83705889a444'} as BiGroupDto];
  set groupIdDropdownValues(val) {
    this._groupIdDropdownValues = val 

    var t = this.form.controls.workSpaceId.value;
    this.form.controls.workSpaceId.setValue(t);
    this.form.controls.workSpaceId.markAsDirty();
  }
  get groupIdDropdownValues() {
    return this._groupIdDropdownValues
  }

  filteredReportIdDropdownValues!: BiReportDto[];

constructor(public httpService: HttpService, public dialogRef: MatDialogRef<EditTreenodeModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

tags : string[] = [];


initialWorkspace = '';
initialReportId = '';
ngOnInit(): void {
    console.log('data for edit modal=',this.data);
    this.form.controls.name.setValue(this.data?.name);
    this.form.controls.description.setValue(this.data?.description);
    this.form.controls.externalUrl.setValue(this.data?.externalUrl);
    this.form.controls.url.setValue(this.data?.url);
    this.form.controls.image.setValue(this.data?.image);
    this.tempImageBase64 = this.data?.image;


    this.initialWorkspace = this.data?.workSpaceId
    this.initialReportId = this.data?.powerBiReportId;
    this.form.controls.workSpaceId.setValue(this.initialWorkspace);
    this.form.controls.powerBiReportId.setValue(this.initialReportId);

    console.log('look here', this.data)
    // this.form.controls.externalUrl.setValue(this.data?.tags);

    // this.form.controls.reportId.valueChanges.subscribe( value => {
    //   this.filteredReportIdDropdownValues = this._filter(value || '')
    // });
    
    this.httpService.getBiGroups().subscribe(x => {
      this.groupIdDropdownValues = this.groupIdDropdownValues.concat(x as BiGroupDto[]);
      console.log('aight', this.initialWorkspace)
      this.form.controls.workSpaceId.setValue(this.initialWorkspace);
      this.form.controls.powerBiReportId.setValue(this.initialReportId);
    });

    this.form.controls.workSpaceId.valueChanges.subscribe(selectedWorkspaceId => {
      console.log('selected workspace id: ', selectedWorkspaceId); // Value inside the input field as soon as it changes
      if(selectedWorkspaceId)
        this.getReportsForWorkSpace(selectedWorkspaceId);
      
    });

    if(this.data?.tags) {
      this.tags = (this.data?.tags as string).split(',');
    }
    


  }

  
  getReportsForWorkSpace(workspaceId: string) {
    this.httpService.getBiReports(workspaceId).subscribe(data => {
      if(data && data.length > 0) {
        this.reportIdDropdownValues = data as BiReportDto[];
        this.filteredReportIdDropdownValues= this.reportIdDropdownValues;
        this.form.controls.workSpaceId.setValue(this.initialWorkspace);
        this.form.controls.powerBiReportId.setValue(this.initialReportId);
    
      }
      
   //here!
      //console.log(this.reportIdDropdownValues);
    });
  }
  

  form = new FormGroup({
    name: new FormControl(''),
    description: new FormControl(''),
    externalUrl: new FormControl(false),
    url: new FormControl(''),
    tags: new FormControl(''),
    image: new FormControl(''),
    workSpaceId: new FormControl(''),
    powerBiReportId: new FormControl(''),
  });

  save() {
    this.form.controls.tags.setValue(this.tags.join(", "));
    this.form.controls.image.setValue(this.tempImageBase64);

    debugger;
    this.dialogRef.close(this.form.value);
  }

  cancel(){
    this.dialogRef.close();
  }


  keywords = ['angular', 'how-to', 'tutorial', 'accessibility'];
  formControl = new FormControl(['angular']);

  announcer = inject(LiveAnnouncer);
  removeTag(tag: string) {
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);

      this.announcer.announce(`removed ${tag}`);
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our keyword
    if (value) {
      this.tags.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  tempImageBase64: string = '';

  updateImageBase64String(base64String: any) {
    this.tempImageBase64 = base64String;
    this.form.controls.image.setValue(base64String);
    this.form.controls.image.markAsDirty();
    }

    displayFn(options: BiReportDto[] | null) {
      return (id: string) => { 
        const correspondingOption = Array.isArray(options) ? options.find(option => option.id === id) : null;
        return correspondingOption ? correspondingOption.name : '';
      }
    }


}

import {NestedTreeControl} from '@angular/cdk/tree';
import {Component, ViewChild} from '@angular/core';
import {MatTreeNestedDataSource, MatTreeModule, MatTree} from '@angular/material/tree';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { AdGroup } from 'src/app/models/adGroup';
import { SubjectArea } from 'src/app/models/subject-area';
import { SnackbarModes } from 'src/app/models/enums/SnackbarModes';
import { TreeHelperServiceService } from 'src/app/services/tree-helper-service.service';
import { SnackbarComponent } from '../snack-bar/snackbar/snackbar.component';
import { ErrorSnackbarComponent } from '../snack-bar/fail-snackbar/error-snackbar/error-snackbar.component';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WarningModalComponent } from '../warning-modal/warning-modal.component';
import { EditTreenodeModalComponent } from '../edit-treenode-modal/edit-treenode-modal.component';
import { EditTreeNodeRequest } from 'src/app/models/TreeSortNode';

/**
 * Food data with nested structure.
 * Each node has a name and an optional list of children.
 */
interface FoodNode {
  name: string;
  children?: FoodNode[];
}

const TREE_DATA: FoodNode[] = [
  {
    name: 'Fruit',
    children: [{name: 'Apple'}, {name: 'Banana'}, {name: 'Fruit loops'}],
  },
  {
    name: 'Vegetables',
    children: [
      {
        name: 'Green',
        children: [{name: 'Broccoli'}, {name: 'Brussels sprouts'}],
      },
      {
        name: 'Orange',
        children: [{name: 'Pumpkins'}, {name: 'Carrots'}],
      },
    ],
  },
];

@Component({
  selector: 'app-report-tree-view',
  templateUrl: './report-tree-view.component.html',
  styleUrls: ['./report-tree-view.component.scss']
})
export class ReportTreeViewComponent {
  treeControl = new NestedTreeControl<any>(node => node.children);
  dataSource = new MatTreeNestedDataSource<FoodNode>();
  adGroups!: AdGroup[];
  selectedAdGroup!: AdGroup;
  subjectAreas: SubjectArea[] = [];
  treeData: FoodNode[] = [];
  isOrderingSubjectAreas = false;
  warningDialogRef!: MatDialogRef<WarningModalComponent>;
  sortableNodes: any[] = [];


  constructor(public httpService: HttpService, private _sanitizer: DomSanitizer, private _snackBar: MatSnackBar, private _router:Router, private dialog: MatDialog, private treeHelper: TreeHelperServiceService) {
    //this.dataSource.data = TREE_DATA;
    //this.dataSource.data = this.subjectAreas;
  }
  @ViewChild('treeSelector') tree!: MatTree<any>;
  @ViewChild('matRef') matRef!: MatSelect;

  ngOnInit(): void {
    this.getAdGroups();
    }

    getAdGroups() {
      try {

        // this.subjectAreas.push({id: 'add'} as SubjectArea)
        // this.dataSource.data = this.subjectAreas;
  
        this.httpService.getAdGroups('')
        .subscribe(adGroups => {
          this.adGroups = adGroups;

          //if adgroup was previously select, then reselect it. 
          if(this.selectedAdGroup) {
            const refreshedAdGroups = adGroups.filter(obj => {
              return obj.id === this.selectedAdGroup.id
            });

            
            const refreshedAdGroup = refreshedAdGroups[0];
            this.populateTree(refreshedAdGroup);
            this.selectedAdGroup = refreshedAdGroup;
            this.getNewMegamenu(refreshedAdGroup.id);
          }
        });
  
      } catch (error: any) {
        const displayMessage = `Failed to fetch config for report. Status: ${error.statusText} Status Code: ${error.status}`;
        console.error(displayMessage);
        return;
      }
    }

    adGroupSelected(adGroup: AdGroup) {
      this.populateTree(adGroup);
      this.selectedAdGroup = adGroup;
      this.getNewMegamenu(adGroup.id);
    }

    getNewMegamenu(adGroupdId: string) {
      this.httpService.getMegaMenuSubjectAreas(adGroupdId);
    }

    populateTree(adGroup: AdGroup) {
      const subjectAreas = adGroup.adGroupSubjectAreaXrefs.map(x => x.subjectArea);
      this.subjectAreas = this.sortList(subjectAreas);
      

      //this.subjectAreas = data as SubjectArea[];
  
      this.subjectAreas.forEach((subjectArea) => {
       subjectArea.safeResourceUrl = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' 
       + subjectArea.image);

       subjectArea.isNew = false;

      // this.tr
       subjectArea.children = subjectArea.categories.sort((a,b) => a.sequence - b.sequence);

       subjectArea.categories.forEach(category => {
        category.children = category.driveReports.sort((a,b) => a.sequence - b.sequence);
        category.children = category.children.concat(category.subCategories.sort((a,b) => a.sequence - b.sequence))

        category.subCategories.forEach(subCategory => {
          subCategory.children = subCategory.driveReports.sort((a,b) => a.sequence - b.sequence);
          subCategory.children = subCategory.children.concat(subCategory.subCategoryTwos.sort((a,b) => a.sequence - b.sequence));

          subCategory.subCategoryTwos.forEach(subCategoryTwo => {
            subCategoryTwo.children = subCategoryTwo.driveReports.sort((a,b) => a.sequence - b.sequence);
          });
        });
        
      });
      });

      this.subjectAreas.push({id: 'add'} as SubjectArea)
      this.dataSource.data = this.subjectAreas;
    }

    populateTreeFromSA() {

   
      this.subjectAreas.forEach((subjectArea) => {
        subjectArea.safeResourceUrl = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' 
        + subjectArea.image);
 
        subjectArea.isNew = false;
 
       // this.tr
        subjectArea.children = subjectArea.categories.sort((a,b) => a.sequence - b.sequence);
 
        subjectArea.categories.forEach(category => {
         category.children = category.driveReports.sort((a,b) => a.sequence - b.sequence);
         category.children = category.children.concat(category.subCategories.sort((a,b) => a.sequence - b.sequence))
 
         category.subCategories.forEach(subCategory => {
           subCategory.children = category.driveReports.sort((a,b) => a.sequence - b.sequence);
           subCategory.children = subCategory.children.concat(subCategory.subCategoryTwos.sort((a,b) => a.sequence - b.sequence));
 
           subCategory.subCategoryTwos.forEach(subCategoryTwo => {
             subCategoryTwo.children = subCategoryTwo.driveReports.sort((a,b) => a.sequence - b.sequence);
           });
         });
         
       });
       });
 
       this.subjectAreas.push({id: 'add'} as SubjectArea)
       this.dataSource.data = this.subjectAreas;
     
    }

  hasChild = (_: number, node: FoodNode) => !!node.children && node.children.length > 0;

  hasCategories = (_: number, node: SubjectArea) => !!node.categories && node.categories.length > 0;

  isSubjectAreaNode(node: any) {
    if(node.hasOwnProperty('categories'))
      return true;
    return false;
  }

  isCategoryNode(node: any) {
    if(node.hasOwnProperty('subCategories'))
      return true;
    return false;
  }
  
  isSubCategoryNode(node: any) {
    if(node.hasOwnProperty('subCategoryTwos'))
      return true;
    return false;
  }

  isSubCategoryTwoNode(node: any) {
    if(node.hasOwnProperty('subCategoryId') &&
    node.hasOwnProperty('driveReports')
    )
      return true;
    return false;
  }

  isDriveReportNode(node: any) {
    if(node.hasOwnProperty('powerBiReportId'))
      return true;
    return false;
  }

  isAddNewSubjectAreaNode(node: any) {
    if(node.id == 'add')
      return true;
    return false;
  }

  deleteNode(node: any) {
    
      if(this.isSubjectAreaNode(node))
        this.deleteSubjectArea(node.id, node.name);
      else if(this.isCategoryNode(node))
        this.deleteCategory(node.id, node.name);
      else if(this.isSubCategoryNode(node))
        this.deleteSubCategory(node.id, node.name);
      else if(this.isSubCategoryTwoNode(node))
        this.deleteSubCategoryTwo(node.id, node.name);
      else if(this.isDriveReportNode(node))
        this.deleteReport(node.id, node.name);

  }



  clearAdSelect() {
    this.matRef.options.forEach((data: MatOption) => data.deselect());
  }

  deleteAdGroup( )  { 
      this.warningDialogRef = this.dialog.open(WarningModalComponent, {
        autoFocus: true,
        width: '400px',
        disableClose: true,
    });

    this.warningDialogRef.componentInstance.promptMessage =
        `Are you sure you want to delete this AD Group '${this.selectedAdGroup.name}'?`;
    this.warningDialogRef.componentInstance.yesBtnLabel = 'Delete';
    this.warningDialogRef.componentInstance.noBtnLabel = 'Return To Section';

    this.warningDialogRef.componentInstance.yesClicked.subscribe(() => {
        this.deleteAdGroupConfirmed();
        this.dialog.closeAll();
    });

    this.warningDialogRef.componentInstance.noClicked.subscribe(() => {
        this.dialog.closeAll();
    });

    this.warningDialogRef.componentInstance.headerIconClose.subscribe(() => {
        this.dialog.closeAll();
  });


  }

  deleteAdGroupConfirmed( )  {
       

    // debugger;
    this.httpService.DeleteAdGroup(this.selectedAdGroup.id).subscribe(success => {
      
      if(success) {
        this.openSnackBar(`Success: Deleted AD Group ${this.selectedAdGroup.name}`, 'close', SnackbarModes.success);

        const adGroupsNew = this.adGroups.filter(( adg ) => {
             return adg.id !== this.selectedAdGroup.id;
          });

        this.adGroups = adGroupsNew;
        // this.adGroupSelected(this.adGroups[0]);

        this.dataSource.data = [];
        this.selectedAdGroup = {} as AdGroup;
        this.clearAdSelect();
      }
       
    });
  }


  deleteSubjectArea(subjectAreaId: string, name: string )  {
    this.httpService.DeleteSubjectArea(subjectAreaId).subscribe(success => {
      
      if(success){
        this.openSnackBar(`Success: Deleted Subject Area ${name}`, 'close', SnackbarModes.success);

       // this.subjectAreas = this.subjectAreas.filter(sa => sa.id !== subjectAreaId);
        this.removeIdFromAnyLevel(subjectAreaId);
      }
       
    });
  }

  deleteCategory(categoryId: string, name: string )  {
    this.httpService.DeleteCategory(categoryId).subscribe(success => {
      
      if(success){
        this.removeIdFromAnyLevel(categoryId);
        this.openSnackBar(`Success: Deleted Category ${name}`, 'close', SnackbarModes.success);
      }
     
    });
  }

  deleteSubCategory(subCategoryId: string, name: string )  {
    this.httpService.DeleteSubCategory(subCategoryId).subscribe(success => {
      
      if(success){
        this.removeIdFromAnyLevel(subCategoryId);
        this.openSnackBar(`Success: Deleted Subcategory ${name}`, 'close', SnackbarModes.success);
      }
       
    });
  }

  deleteSubCategoryTwo(subCategoryTwoId: string, name: string )  {
    this.httpService.DeleteSubCategoryTwo(subCategoryTwoId).subscribe(success => {

      if(success){
        this.removeIdFromAnyLevel(subCategoryTwoId);
        this.openSnackBar(`Success: Deleted Second Subcategory  ${name}`, 'close', SnackbarModes.success);
      }
     
    });
  }

  deleteReport(reportId: string, name: string )  {
    this.httpService.DeleteReport(reportId).subscribe(success => {
      
      if(success){
        this.removeIdFromAnyLevel(reportId);
        this.openSnackBar(`Success: Deleted Report ${name}`, 'close', SnackbarModes.success);
      }
      
    });
  }

  // Removing from the root levels of the tree can be quite complex.
  // This allows us to search the tree for any particular guid and remove by id.
  removeIdFromAnyLevel(id: string) {
    this.subjectAreas = this.subjectAreas?.filter(sa => sa.id !== id);

    this.subjectAreas?.forEach(sa => {
      sa.children = sa.children?.filter(c => c.id !== id);
      sa.children?.every(c => {
        if(!c.children)
          return false;
        c.children = c.children?.filter(sc => sc.id !== id);
        c.children?.every(sc => {
          if(!sc.children)
            return false;
          sc.children = sc?.children?.filter(sct => sct.id !== id);
          sc.children?.every(sct => {
            if(!sct.children)
              return false;
            sct.children = sct.children?.filter(r => r.id !== id);
            return true;
          })
          return true;
        })
        return true;
      })

    });
    //this.populateTreeFromSA();

    // this.dataSource = new MatTreeNestedDataSource<FoodNode>();
    // let _data = this.subjectAreas;
     this.dataSource.data = JSON.parse(JSON.stringify(this.subjectAreas)) as SubjectArea[];
    // this.dataSource.data = _data;
    // this.dataSource.data = this.dataSource.data.slice();
    //this.tree.renderNodeChanges(_data);
  }

  // addReport() {

  //   this.treeHelper.adGroupId = this.selectedAdGroup.id;
  //   this.treeHelper.subjectAreaId = this.sele
  //   this._router.navigate(['add-report']);
  // }
  navigateToAddReport() {
    this._router.navigate(['add-report', '', '']);
  }

  addReport(node: any) {
    let nodeType = '';
    if(this.isSubjectAreaNode(node))
      nodeType = 'SubjectArea';
    else if(this.isCategoryNode(node))
      nodeType = 'Category';
    else if(this.isSubCategoryNode(node))
      nodeType = 'SubCategory';
    else if(this.isSubCategoryTwoNode(node))
      nodeType = 'SubCategoryTwo';
    else if(this.isDriveReportNode(node))
      nodeType = 'Report';
    else {
      // AD is not a true node so its an exception case.
      nodeType = 'AdGroup'
      this._router.navigate(['add-report', nodeType, this.selectedAdGroup.id]);
      return;
    }
      

    
    this._router.navigate(['add-report', nodeType, node.id]);
  }

 
  openSnackBar(message: string, action: string, mode: number) {

   

    if(mode == SnackbarModes.success){
      this._snackBar.openFromComponent(SnackbarComponent, {
        duration: 3000,
        panelClass: 'snackbar-style',
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
    } else if (mode == SnackbarModes.error) {
      this._snackBar.openFromComponent(ErrorSnackbarComponent, {
        duration: 3000,
        panelClass: 'snackbar-style',
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
    }
    
  }
 
  subjectAreaOrdersUpdated(subjectAreasSorted: SubjectArea[]) {
    this.isOrderingSubjectAreas = false;
    this.getAdGroups();
     
    // this.subjectAreas = subjectAreasSorted;
    // this.populateTreeFromSA();
    // this.getNewMegamenu(this.selectedAdGroup.id);
  }

  nodeOrdersUpdated(nodesSorted: any[]) {
    this.isOrderingSubjectAreas = false;
    this.subjectAreas = nodesSorted;
    this.populateTreeFromSA();
    this.getNewMegamenu(this.selectedAdGroup.id);
  }

  showDragSortView() {
    this.isOrderingSubjectAreas = true;
  }

  sortNode(node?: any) {

    if(!node)
      this.sortableNodes = this.subjectAreas;
    else 
      this.sortableNodes = node.children;

      this.isOrderingSubjectAreas = true;

}

  sortList(subjectAreas: SubjectArea[]): SubjectArea[] {
    const subjectAreasSorted = subjectAreas.sort((a, b) => (a.sequence > b.sequence) ? 1 : -1);
    return subjectAreasSorted;
  }

  isSortable(node: any) {
    const notAReportNode = !this.isDriveReportNode(node);
    const hasMoreThanSingleChild = node.children && node.children.length > 1;
    return notAReportNode && hasMoreThanSingleChild;
  }

  isEditable(node: any) {
    if(this.isSubjectAreaNode(node) || this.isCategoryNode(node) || this.isSubCategoryNode(node) || this.isSubCategoryTwoNode(node) || this.isDriveReportNode(node))
      return true;

    return false;
  }


  openEditNodeDialog(node: any) {
    console.log('node', node);
    const dialogRef = this.dialog.open(EditTreenodeModalComponent, {
        width: '420px',
        height: '615px',
        // panelClass: 'full-screen-modal'
        data: node
      });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        let nodeType = '';
        if(this.isSubjectAreaNode(node))
          nodeType = 'SubjectArea';
        else if(this.isCategoryNode(node))
          nodeType = 'Category';
        else if(this.isSubCategoryNode(node))
          nodeType = 'SubCategory';
        else if(this.isSubCategoryTwoNode(node))
          nodeType = 'SubCategoryTwo';
        else if(this.isDriveReportNode(node))
          nodeType = 'Report';

        const request: EditTreeNodeRequest = {id: node.id, name: result.name, description: result.description, externalUrl: result.externalUrl, nodeType: nodeType, webUrl: result.url, tags: result.tags, image: result.image, workSpaceId: result.workSpaceId, powerBiReportId: result.powerBiReportId} as EditTreeNodeRequest;
        this.treeHelper.editNode(request).subscribe(x => {
          this.openSnackBar(`Success: Edited ${nodeType}`, 'close', SnackbarModes.success);
          this.getAdGroups();
        });

         
      }
      
    });
  }




}

import { Component, Input } from '@angular/core';
import { FeaturedReport } from 'src/app/models/featuredreports';
import { WindowServiceService } from 'src/app/services/window-service.service';

@Component({
  selector: 'app-featured-reports-viewer',
  templateUrl: './featured-reports-viewer.component.html',
  styleUrls: ['./featured-reports-viewer.component.scss']
})
export class FeaturedReportsViewerComponent {
  @Input() featuredReportList!: FeaturedReport[] | undefined;
  baseDir = '../../../assets/images/DriveUXRelatedNewsimg0';
  mobileView = false;

  getImageDir(count: number) {
    return `${this.baseDir}${count+1}.png`
  }
  constructor(public windowsService: WindowServiceService) {

  }
  
    routeToUrl(url: string) {
      
      window.open(url, "_blank");
    
    }
}

<div  [hidden]="!isVisible()" [formGroup]="featuredReportsFormGroup" class="menu-edit-header">

   


    <mat-accordion >
        <mat-expansion-panel   style="background: #E2EEF8; margin: 0px 90px;">
          <mat-expansion-panel-header>
            <mat-panel-title>  <h1 class="topHeaderText">Featured Reports Editor</h1></mat-panel-title>
            <!-- <mat-panel-description> This is a summary of the content </mat-panel-description> -->
          </mat-expansion-panel-header>
          <div style="background: #E2EEF8;">
            <div formArrayName="featuredReportsFormArray"  *ngFor="let ni of getControls(); let i = index;"
            style="display: flex; flex-direction: column; width: 100%; padding-right: 30px;
             padding-left: 30px;" >
       
               <div  [formGroupName]="i" style="display: flex; flex-direction: column; width: 100%;">
                <div class="articleHeaderText">Article {{i+1}}</div>
    
                
                <div style="width: 100%;">
                   
                       <div style="width: 100%;">   
                           <label class="labelText"><span style="color: red">*</span> Title (Maximum 45 characters)</label>
                           <mat-form-field style="width: 100%;" class="input"  appearance="outline" >
                            
                            <input formControlName="title" type="tel" matInput >
                            <!-- <mat-error >Not a Valid Title</mat-error> -->
                           </mat-form-field>
                       </div>
    
                       <div style="width: 100%;">   
                        <label class="labelText"><span style="color: red">*</span>Description</label>
                        <mat-form-field style="width: 100%;" class="input"  appearance="outline" >
                            <textarea matInput minRows="3" formControlName="description" placeholder="description"></textarea>
                            <!-- <input   formControlName="description" type="tel" matInput > -->
                            <mat-error >Not a Valid Description</mat-error>
                        </mat-form-field>
                    </div>
           
                       <div style="width:100%;">   
                           <label class="labelText"><span style="color: red">*</span> CTA  (Maximum 45 characters)</label>
                           <mat-form-field style="width: 100%;"  class="input"  appearance="outline" >
                           
                            <input  formControlName="callToAction" type="tel" matInput >
                            <mat-error >Not a Valid Call To Action</mat-error>
                           </mat-form-field>
                       </div>
               
                      
           
                       <div style="width: 100%;">   
                           <label class="labelText"><span style="color: red">*</span> URL</label>
                           <mat-form-field style="width: 100%;" class="input"  appearance="outline" >
                           
                           <input formControlName="url" type="tel" matInput >
                           <mat-error >Not a Valid Url</mat-error>
                           </mat-form-field>
                       </div>
                </div>
       
               </div>
               
           </div>
    
           
            <div class="cancel-save-buttons">
            <button  mat-raised-button color="primary" [disabled]="!formValid()" (click)="save()"  value="Submit" >Save</button>
            </div>
      
       
        
            </div>
        </mat-expansion-panel>
     
      </mat-accordion>

   

    
  </div>











  
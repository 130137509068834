

<div *ngIf="!loginDisplay">
    <p>Please sign-in to Drive.</p>
</div>

<div *ngIf="loginDisplay">
   
    
    <div id="DriveUXbannermain" style="position: relative;">
        <div id="bannerimage" style="" >
            <!-- style=" background-image: url('../../../assets/images/homeimage.jpg')" -->
            <!-- <img src="../../../assets/images/homeimage2.jpg" alt=""> -->

            <div class="DriveUXbannermaindivwithpara">
                <div>
                    <h1 class="DriveUXbannermainHeading" >Welcome to Gallagher Drive</h1>
                    <h4 class="DriveUXbannermainSubHeading">Powerful Insights That Drive Action To Face Your Future With Confidence.   </h4>
                </div>
            </div>

            <!-- <div style="position: sticky; position: -webkit-sticky;" id="feedback"><a target="_blank" href="https://survey.sjc1.qualtrics.com/jfe/form/SV_3gG6jZdz2WKGUxE"><img src="../../../assets/images/DriveUXbannerfeedback.svg" alt=""></a></div> -->
        </div>
        
    </div>
    <div id="DriveUXbanner">
        <div id="DriveUXbannerheadingmain" class="uni-pad">
            <div id="DriveUXbannerheading">
                <!-- <h1>Gallagher Drive</h1>
                <div id="DriveUXbannerheadingline" style="width: 264px!important;"></div> -->
                
                <p>Gallagher Drive is our premier data and analytics platform that transforms raw data into powerful
                    insights for you and your clients. These tools create data visualizations that help provide your
                    clients confidence in their risk management program by using data and analytics to show they
                    have
                    the right coverages, are placed with the right carrier, and are getting the right price. Our
                    Gallagher Drive analytics consultants provide industry-leading consulting expertise by
                    leveraging
                    our rich in-house data sets to help your clients get the most out of their risk management
                    programs
                    and lower their cost of risk.</p>
            </div>
        </div>
    </div>




    <app-drive-features-viewer [featuredReportList]="driveFeatures">
        
    </app-drive-features-viewer>




    <div id="DriveUXToolsandCalculatorsmain" class="uni-pad">
        <div id="DriveUXToolsandCalculators">
            <div style="margin-top: 24px;" class="headerMessage" id="DriveUXToolsandCalculatorsheading">
                <h2 class="sectionHeadings">Tools & Calculators</h2>
            </div>

          
                <div id="featuredTools" >
                    <div style="width: 49%; display: inline-block; margin-right: 8px; border-style: none;
                        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2)" id="DriveUXToolsandCalculatorsbox1">
                        <div id="DriveUXToolsandCalculatorsboximg1">
                            <img src="../../../assets/images/umbrellaimage.png" alt="">
                        </div>
                        <div id="DriveUXToolsandCalculatorsboxcont1">
                            <div id="DriveUXToolsandCalculatorsboxcont1box">
                                <h3 class="boxH3">Umbrella Limits Calculator</h3>
                                <p>Tell us five (5) basic things about your business and we'll use Gallagher
                                    analytics
                                    to
                                    show you how your Umbrella Policy limits compare to companies like
                                    yours—including
                                    target limit and exposure data with real-world claim examples.</p>
                            </div>
                            <div style="cursor: pointer;"  id="DriveUXToolsandCalculatorsboxcontbottom">
                                <!-- <div id="DriveUXToolsandCalculatorsboxcontline"></div> -->
                                <a (click)="navigateToUrl('https://umbrellalimits.tools.ajg.com/','002FDF42-8E54-4B75-B93B-75BE663E7E2D')" style="cursor: pointer;"  target="_blank" ><img
                                    src="../../../assets/images/DriveUXToolsandCalculatorsrightimage.svg" alt=""><span><u>Start
                                    Your
                                    Comparison</u></span></a>
                                <!-- <a href="https://umbrellalimits.tools.ajg.com/"><img
                                        src="../../../assets/images/DriveUXToolsandCalculatorsrightimage.svg" alt=""><span>Start
                                        Your
                                        Comparison</span></a> -->
                            </div>
                        </div>
                    </div>

                    <div style="width: 49%; border-style: none;
                        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2)" id="DriveUXToolsandCalculatorsbox2main">
                        <div  id="DriveUXToolsandCalculatorsbox2">
                            <div id="DriveUXToolsandCalculatorsboxcont2">
                                <div id="DriveUXToolsandCalculatorsboxcont2box">
                                    <h3 class="boxH3">Cyber Liability Calculator</h3>
                                    <p>Tell us five (5) things about your business and we'll use Gallagher analytics
                                        to
                                        calculate and estimate the optimal Cyber Liability coverage limits for your
                                        level of
                                        exposure in case of a cyber breach or ransomware attack. <span style="opacity: 0;">__ __ __ __ _ _ _ _ _ __</span></p>
                                </div>
                                <div style="cursor: pointer;"  id="DriveUXToolsandCalculatorsboxcontbottom1">
                                    <!-- <div id="DriveUXToolsandCalculatorsboxcontline1"></div> -->
                                    <a style="cursor: pointer;" (click)="navigateToUrl('https://cyberrisk.tools.ajg.com/', '6BCC86CC-C387-4075-8E9C-3102524BA2F4')" target="_blank" ><img
                                        src="../../../assets/images/DriveUXToolsandCalculatorsrightimage.svg" alt=""><span><u>Start
                                        Your
                                        Comparison</u></span></a>
                                    <!-- <a href="https://cyberrisk.tools.ajg.com/"><img
                                            src="../../../assets/images/DriveUXToolsandCalculatorsrightimage.svg" alt=""><span>Assess
                                            Your Cyber Risk</span></a> -->
                                </div>
                            </div>
                            <div id="DriveUXToolsandCalculatorsboximg2">
                                <img src="../../../assets/images/cyberimage.png" alt="">
                            </div>
                        </div>
                    </div>


                </div>
          

        </div>
    </div>

    <app-news-insights-viewer [newsInsightsList]="newsAndInsights">
        
    </app-news-insights-viewer>
    
    <!-- <div id="DriveUXTTrendingProductmain"  class="uni-pad">
        <div id="DriveUXTTrendingProduct">
            <div style="margin-top: 24px;" id="DriveUXTTrendingProductheading">
                <h2 class="sectionHeadings">Related News & Insights</h2>
                <div id="DriveUXInsightsToWinline2" style="width: 272px;"></div>
                <p>Stay connected with the company that’s connecting the dots with what’s happening in the industry and
                    around the world.</p>
            </div>

            <div id="DriveUXTTrendingProduct3box">
                <div id="DriveUXTTrendingProduct3box1">
                    <div id="DriveUXTTrendingProduct3box1img">
                        <img src="../../../assets/images/DriveUXRelatedNewsimg01.png" alt="">
                    </div>
                    <div id="DriveUXTTrendingProduct3boxcont1">
                        <h3>The Importance of Data Risk Management</h3>
                        <a  target="_blank" href="https://www.ajg.com/us/news-and-insights/2020/dec/importance-data-risk-management/"><img
                                src="../../../assets/images/DriveUXInsightsToWinrighticon.svg" alt=""><span>Learn
                                More</span></a>
                    </div>
                </div>

                <div id="DriveUXTTrendingProduct3box2">
                    <div id="DriveUXTTrendingProduct3box2img">
                        <img src="../../../assets/images/DriveUXRelatedNewsimg02.png" alt="">
                    </div>
                    <div id="DriveUXTTrendingProduct3boxcont2">
                        <h3>Integrating Data in Risk Management</h3>
                        <a  target="_blank"
                            href="https://www.ajg.com/us/news-and-insights/2021/jan/integrating-data-into-risk-management/"><img
                                src="../../../assets/images/DriveUXInsightsToWinrighticon.svg" alt=""><span>Learn
                                More</span></a>
                    </div>
                </div>

                <div id="DriveUXTTrendingProduct3box3">
                    <div id="DriveUXTTrendingProduct3box3img">
                        <img src="../../../assets/images/DriveUXRelatedNewsimg03.png" alt="">
                    </div>
                    <div id="DriveUXTTrendingProduct3boxcont3">
                        <h3>Building a Data-Centric Culture</h3>
                        <a  target="_blank" href="https://www.ajg.com/us/news-and-insights/2021/jan/building-data-centric-culture/"><img
                                src="../../../assets/images/DriveUXInsightsToWinrighticon.svg" alt=""><span>Learn
                                More</span></a>
                    </div>
                </div>

            </div>

        </div>
    </div> -->



    <footer>
        <div id="footersocialmedia" class="uni-pad">
            <div id="DriveUXsitemapmain">
                <div id="DriveUXsitemap" class="footerlinksection">
                    <div  style="margin-left: 10%;" >
                        <img  src="../../../assets/images/FooterLogo2.png"  alt="">
                    </div>
               
                    <div id="DriveUXsitemapIndustries">

                        <p class="Industries"><u>US Resources</u></p>

                        <a style="cursor: pointer;" target="_blank" 
                            href="https://teams.microsoft.com/l/team/19%3aeFpJ2xNGPlXo2vUHbAVurkuBnU0v0AAiPPCUnd4p5do1%40thread.tacv2/conversations?groupId=3734acb6-368c-47bb-9e6e-d086fd3ea8c6&tenantId=6cacd170-f897-4b19-ac58-46a23307b80a">
                            <p>Teams Support</p>
                        </a>

                        <a style="cursor: pointer;" target="_blank" 
                            href="https://ajg0.sharepoint.com/sites/GGBUS-Gdrive/SitePages/Getting-Started-and-Support.aspx">
                            <p>Drive Support SharePoint</p>
                        </a>
                        <a style="cursor: pointer;"  target="_blank"  href="https://ajg0.sharepoint.com/sites/GGBUS-Gdrive/SitePages/home.aspx">
                            <p>Drive Home SharePoint</p>
                        </a>
                        <a style="cursor: pointer;"  target="_blank"  href="https://ajg0.sharepoint.com/sites/GGBUS-Gdrive/SitePages/Training-+-Resources.aspx#userguides">
                            <p>User Guides</p>
                        </a>
                        <a  style="cursor: pointer;" target="_blank"  href="https://ajg0.sharepoint.com/sites/GGBUS-Gdrive/Gallagher%20Drive%20%20User%20Guides/Forms/AllItems.aspx?id=%2fsites%2fGGBUS-Gdrive%2fGallagher%20Drive%20%20User%20Guides%2f_Request%20Access%20to%20Gallagher%20Drive_User%20Guide.pdf&parent=%2fsites%2fGGBUS-Gdrive%2fGallagher%20Drive%20%20User%20Guides">
                            <p>IDM Guide</p>
                        </a>
                       
                        <!-- <a href="">
                            <p>Footer Link</p>
                        </a>
                        <a href="">
                            <p>Footer Link</p>
                        </a>
                        <a href="">
                            <p>Footer Link</p>
                        </a>
                        <a href="">
                            <p>Footer Link</p>
                        </a> -->
                    </div>

                    <div id="DriveUXsitemapInsurance">
                        <p class="Industries" style="opacity: 0;"><u>US Resources2</u></p>
                        <a  style="cursor: pointer;" target="_blank"  href="https://ajg0.sharepoint.com/sites/GGBUS-sales/SitePages/BOR-Support.aspx">
                            <p>BOR Support</p>
                        </a>
                        <a style="cursor: pointer;"  target="_blank"  href="https://ajg0.sharepoint.com/sites/GGBAmericas-Core360">
                            <p>CORE360</p>
                        </a>
                        <a  style="cursor: pointer;" target="_blank"  href="https://ajg0.sharepoint.com/sites/GGBAmericas-Core360/SitePages/Client-Situations-Tool-Guide.aspx">
                            <p>Risk Profile Presentation</p>
                        </a>
                        <a  style="cursor: pointer;" target="_blank"  href="https://gallagher.my.salesforce.com">
                            <p>WIN</p>
                        </a>
                        <a style="cursor: pointer;" (click)="navigateToReportView('42cb151b-4586-418f-b0e8-61326c2ed4ba','cd209299-4809-42f4-b2c6-0e84d3c5eb38','3d228932-3a6f-4803-992d-b609d7ed5d3f')" >
                            <p>Connect Partner Finder</p>
                        </a>

                        <!-- <p class="Insurance">UK Resources</p>

                        <a
                            href="https://go.ajgco.com/ib/uk/marcomms/ClientServicingToolkit/Documents/Requesting%20access%20for%20Gallagher%20Drive%20via%20IDM.pdf#search=drive%20uk">
                            <p>IDM Guide</p>
                        </a>
                        <a
                            href="https://ajguk.sharepoint.com/sites/GGBUK-marcomms-ClientServicingToolkit/SitePages/inner-pages/Sales_Operations_subpage2.aspx">
                            <p>Training & User Guides</p>
                        </a> -->
                        <!-- <a href="">
                            <p>Footer Link</p>
                        </a>
                        <a href="">
                            <p>Footer Link</p>
                        </a>
                        <a href="">
                            <p>Footer Link</p>
                        </a>
                        <a href="">
                            <p>Footer Link</p>
                        </a> -->
                    </div>

                    <!-- <div id="DriveUXsitemapConsulting">
                        <a href="">
                            <p class="Consulting">Consulting</p>
                        </a>
                        <a href="">
                            <p>Footer Link</p>
                        </a>
                        <a href="">
                            <p>Footer Link</p>
                        </a>
                        <a href="">
                            <p>Footer Link</p>
                        </a>
                        <a href="">
                            <p>Footer Link</p>
                        </a>
                        <a href="">
                            <p>Footer Link</p>
                        </a>
                        <a href="">
                            <p>Footer Link</p>
                        </a>
                    </div>

                    <div id="DriveUXsitemapAboutGallagher">
                        <a href="">
                            <p class="AboutGallagher">About Gallagher</p>
                        </a>
                        <a href="">
                            <p>Footer Link</p>
                        </a>
                        <a href="">
                            <p>Footer Link</p>
                        </a>
                        <a href="">
                            <p>Footer Link</p>
                        </a>
                        <a href="">
                            <p>Footer Link</p>
                        </a>
                        <a href="">
                            <p>Footer Link</p>
                        </a>
                        <a href="">
                            <p>Footer Link</p>
                        </a>
                    </div> -->

                </div>
                <div id="DriveUXsitemapline"></div>
            </div>
            <div id="DriveUXfootersocialmediamain">
                <div id="DriveUXfootersocialmedia">
                    <a target="_blank"  href="https://www.linkedin.com/company/gallagher-global/"><img src="../../../assets/images/linkedin.svg" alt=""></a>
                    <a target="_blank"  href="https://www.twitter.com/GallagherGlobal"><img src="../../../assets/images/footer-social-icon-X.svg" alt=""></a>
                    <a target="_blank" href="https://www.youtube.com/GallagherGlobal"><img src="../../../assets/images/youtube.svg" alt=""></a>
                    <a target="_blank" href="https://www.instagram.com/gallagherglobal/"><img src="../../../assets/images/instagram.svg" alt=""></a>
                    <a target="_blank" href="https://www.facebook.com/GallagherGlobal/"><img src="../../../assets/images/facebook.svg" alt=""></a>
                    <!-- <a href=""><img src="../../../assets/images/snapchat.svg" alt=""></a> -->
                </div>
                <div id="DriveUXfooterbacktotop">
                    <a href=""><span class="DriveUXfooterbacktotoptext">TOP</span><img src="../../../assets/images/backtotopicon.svg"
                            alt=""></a>
                </div>
            </div>
        </div>
        <nav>
            <div id="DriveUXfooternavmain" class="uni-pad">
                <div id="DriveUXfooternav">
                    <p><a href="">Contact Us</a></p>
                    <p><a href="">Terms of Use</a></p>
                    <p><a href="">Privacy Policy</a></p>
                </div>
                <div id="DriveUXfootercopyright">
                    <p>&copy; 2023 Arthur J. Gallagher & Co.</p>
                </div>
            </div>
        </nav>
    </footer>
</div>
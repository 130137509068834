import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NewsInsights } from 'src/app/models/newsInsights';
import { WindowServiceService } from 'src/app/services/window-service.service';

@Component({
  selector: 'app-news-insights-viewer',
  templateUrl: './news-insights-viewer.component.html',
  styleUrls: ['./news-insights-viewer.component.scss']
})
export class NewsInsightsViewerComponent {
  @Input() newsInsightsList!: NewsInsights[] | undefined;
  baseDir = '../../../assets/images/DriveUXRelatedNewsimg0';
  mobileView = false;

  getImageDir(count: number) {
    return `${this.baseDir}${count+1}.png`
  }
  constructor(public windowsService: WindowServiceService) {

  }
  
    routeToUrl(url: string) {
      
      window.open(url, "_blank");
    
    }
  // ngOnChanges(changes: SimpleChanges) {
  //   console.log(changes['newsInsightsList']);
  //   if(changes['newsInsightsList'] && changes['newsInsightsList'].currentValue)
  //   {
  //    // debugger;
  //     const listNewsAndInsights = changes['newsInsightsList'].currentValue;

  //     if (listNewsAndInsights && listNewsAndInsights.length > 0) {
  //       this.displayNewsInsights(listNewsAndInsights);
  //     }
      
    
  //   }
  // }

  // displayNewsInsights(newInsights: NewsInsights[]) {

  // }

  // ngOnInit(): void {
  //   if(!this.newsInsightsList || this.newsInsightsList.length < 1) {
  //     this.addBlankNewsInsights();
  //   }
      
  // }
}
